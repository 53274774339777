var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"p1IIpMEFsu1uJDsWNiEUt"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://1f17405a5eb84da78a2f0ae69370b5a4@o215602.ingest.sentry.io/5697612',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT_SLUG || 'local',
  ignoreErrors: [
    "undefined is not an object (evaluating 'r.DomainData')",
    "Cannot read property 'DomainData' of undefined",
    "null is not an object (evaluating 'this.selector.appendChild')",
    'Failed to fetch',
    'TypeError_Failed to fetch',
    'A network error occurred.',
    'Network request failed',
    'Network Error',
    'network error (Error)',
    'The request timed out.',
    'La requête a expiré.',
    'Request aborted',
    "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    'WKWebView API client did not respond to this postMessage',
    'IMUID is not defined',
    'UET is not defined',
    'translate_fp is not defined',
    'ResizeObserver loop completed with undelivered notifications.',
    'Unexpected end of input',
  ],
  denyUrls: [/otSDKStub/i, /recaptcha/i, /widgets/i, /safari/i],
});
